@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-repeat: no-repeat;
    background-size: cover;
}

nav ul {
    list-style-type: none;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    background-color: white;
    border-radius: 15px;
}

#mainNav ul li {
    display: inline;
    margin-right: 10px;
}

#mainNav a:link {
    text-decoration: none;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}